<template>
<div class="basic-information mt-8">
  <div class="d-flex justify-start align-center">
    <div class="mr-6 text-right left">名称</div>
    <div class="right name-edit" @click="e=>{if(item.item_progress!=100){isNameEdit = true}}" v-if="!isNameEdit">{{item.item_name}}</div>
    <div class="w-100" v-if="isNameEdit && item.item_progress!=100">
      <v-text-field class="pt-0" v-model="nameEdit" v-on:keyup.enter="saveItemName"></v-text-field>
    </div>
  </div>
  <div class="mt-4 d-flex justify-start align-center">
    <div class="mr-6 text-right left">起止时间</div>
    <div class="right">
      <span>{{itemStart}} - {{itemEnd}}</span>
      <v-menu offset-y v-model="popupShow" v-if="item.item_progress!=100">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="ml-4">
            <v-icon style="font-size: 18px;">mdi-pencil</v-icon>
          </span>
        </template>
        <div class="popup px-4 py-4" @click="stopEvent">
          <div class="w-100 date-picker mt-8">
            <date-range-picker ref="picker" opens="right" :locale-data="{ 

firstDay: 1,

format: 'yyyy-mm-dd',

applyLabel: '确定',

cancelLabel: '取消',

daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],

monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

}" :minDate="minDate" :maxDate="maxDate" :dateRange="dateRange" :ranges="false" @update="datePicker">
              <template v-slot:input="picker" style="min-width: 350px;">
                {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
              </template>
            </date-range-picker>
          </div>
          <div class="w-100 mt-8">
            <v-text-field class="w-100 pt-0" v-model="remarks" placeholder="请输入修改时间的备注信息"></v-text-field>
          </div>
          <div class="w-100 mt-4">
            <v-file-input truncate-length="15" placeholder="请上传不超过50MB大小的文件，最多可上传 9 个"></v-file-input>
          </div>
          <div class="actions mt-16 text-right">
            <v-btn class="mr-4" @click="cancelDateEdit">取消</v-btn>
            <v-btn color="primary" @click="saveDateInfo">确定</v-btn>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
  <div class="mt-4 d-flex justify-start align-center">
    <div class="mr-6 text-right left">可见范围</div>
    <div class="right">
      <span>{{this.item.item_visible_range==0?'全公司':'仅相关成员'}}</span>
      <v-menu offset-y v-if="item.item_progress!=100">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="ml-4">
            <v-icon style="font-size: 18px;">mdi-pencil</v-icon>
          </span>
        </template>
        <v-list class="py-0">
          <v-list-item class="py-0" @click="updateVisible(0)">
            全公司
          </v-list-item>
          <v-list-item class="py-0" @click="updateVisible(1)">
            仅相关成员
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
  <div class="mt-4 d-flex justify-start align-center">
    <div class="mr-6 text-right left">项目描述</div>
    <v-text-field v-model="item.item_description" v-on:keyup.enter="saveItemDesc" :disabled="item.item_progress==100"></v-text-field>
  </div>
  <div class="mt-4 d-flex justify-start align-top">
    <div class="mr-6 text-right left">关联对象</div>
    <div class="right">
      <div class="obj-list">
        <div class="obj d-flex justify-start" v-for="(itemObj, index) in itemParentObjects">
          <span>
            <v-icon>mdi-target</v-icon>
          </span>
          <span>
            {{itemObj.ob_name}}
          </span>
          <span v-if="item.item_progress!=100" class="ml-2 remove-parent-object" @click="removeParentObject(itemObj.ob_id)" style="cursor:pointer;">
            <v-icon style="font-size: 18px;">mdi-close-circle-outline</v-icon>
          </span>

          <v-menu v-if="index+1==itemParentObjects.length && item.item_progress!=100" :close-on-content-click="false" v-model="targetMenu" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span class="ml-4" v-bind="attrs" v-on="on">
                <v-icon style="font-size: 18px;">mdi-pencil</v-icon>
              </span>
            </template>
            <MyObjectPicker @pick="inputTarget" onlyObj="true"/>
          </v-menu>
        </div>

        <v-menu v-if="itemParentObjects.length==0 && item.item_progress!=100" :close-on-content-click="false" v-model="targetMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span class="ml-4" v-bind="attrs" v-on="on">
              <v-icon style="font-size: 18px;">mdi-pencil</v-icon>
            </span>
          </template>
          <MyObjectPicker @pick="inputTarget" onlyObj="true"/>
        </v-menu>

      </div>
    </div>
  </div>
  <div class="mt-4 d-flex justify-start align-start">
    <div class="mr-6 text-right left">附件</div>
    <v-container class="right">
			<v-row v-if="item.item_progress!=100">
				<a @click="fileInput">点击上传</a>
				<input type="file" ref="fileIntercom" style="display:none;" @change="insertFile">
      <span>（请上传不超过50MB大小的文件，最多可上传9个）</span>
			</v-row>
			<v-row v-for="file in item.item_files" class="pl-5">
				<v-icon color='rgb(73, 185, 221)' style="margin-right: 10px;">mdi-file-upload</v-icon>
				<a :href="fileDownloadEndPoint + '/' + file.file_id" download style="color:rgb(100, 116, 129);">{{ file.file_name }}</a>
				<v-icon v-if="item.item_progress!=100" size="15" color="rgb(169, 184, 191)" style="cursor:pointer;" class="file_remove_close pl-5" @click="removeFile(file.file_id)">mdi-close-circle</v-icon>
			</v-row>
    </v-container>
  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import * as endPoint from "@/config/endpoints";
import {
  getUser
} from '@/utils/authUser.service';
import {
  constant
} from "@/constants/constant.js";

export default {
  name: 'ItemBasicInfo',
  components: {
    DateRangePicker,
    MyObjectPicker: () => import('@/components/common/MyObjectPicker.vue'),
  },
  props: ['item'],
  data() {
    return {
      tab: null,
      targetMenu: false,
      isNameEdit: false,
      nameEdit: this.item.item_name,
      minDate: '2018-01-01',
      maxDate: '2050-12-31',
      popupShow: false,
      remarks: '',
      isShowObj1: 0,
      isShowObj2: 0,
      targetParentMenu: false,
    }
  },
  computed: {
    ...mapGetters('okr', ['okrObj']),
		fileDownloadEndPoint() {
      return endPoint.FILE_DOWNLOAD;
    },
    dateRange: function () {
      let start = this.item.item_start_date;
      let end = this.item.item_end_date;
      return {
        startDate: start + ' 00:00:00',
        endDate: end + ' 00:00:00'
      }
    },
    itemStart: function () {
      return this.dateFormatter(new Date(this.item.item_start_date));
    },
    itemEnd: function () {
      return this.dateFormatter(new Date(this.item.item_end_date));
    },
    itemParentObjects: function () {
			if(!this.item.item_parent_object){
				return [];
			}
      let listStr = this.item.item_parent_object.split(",");
      return listStr.map((item) => {
        let index = this.okrObj.findIndex((o) => o.ob_id == item);
        if (index != -1) return this.okrObj[index];
      }).filter((o) => {
        return !!o;
      })
    },

  },
  created() {
    this.getOkrs({})
  },
  mounted() {
    // this.getOkrs({})
  },
  methods: {
    ...mapActions('item', ['updateItemByField']),
    ...mapActions('okr', ['getOkrs']),
		...mapActions('fileUpload', ['initFiles', 'fileUpload','oneFileUpload', 'deleteFileUploadById']),
		fileInput() {
      this.$refs.fileIntercom.click();
    },
		removeFile(delFileId){
			this.item.item_files = this.item.item_files.filter(file=>file.file_id!=delFileId)

			let re = new RegExp("^" + delFileId + ",|," + delFileId + "|^" + delFileId + "$", "g");
      let payload = {
        item_id: this.item.item_id,
        item_uploaded_file: this.item.item_uploaded_file.replace(re, ""),
      };
      this.updateItemByField(payload);
		},
		insertFile(e) {
      this.display_files = true;
      this.file = e.target.files[0];
			if(this.file !== ''){
				var formData = new FormData();
				formData.append("files", this.file);
				this.oneFileUpload(formData).then((res)=>{
					if (!!res) {
						this.item.item_files.push(res[0]);
						let payload = {
							item_id: this.item.item_id,
							item_uploaded_file: (this.item.item_uploaded_file + "," + res[0].file_id).replace(/^[,]+|[,]+$/g, ""),
						}
						this.updateItemByField(payload)
						this.$refs.fileIntercom.value = '';
					}	
				})
			}
      
    },
    removeParentObject(delObjectId) {
      let re = new RegExp("^" + delObjectId + ",|," + delObjectId + "|^" + delObjectId + "$", "g");
      let payload = {
        item_id: this.item.item_id,
        item_parent_object: this.item.item_parent_object.replace(re, ""),
      };
      this.updateItemByField(payload);
    },
    inputTarget(e) {
      this.targetMenu = false;
      if (!!e.ob_id) {
        if (("," + this.item.item_parent_object + ",").search("," + e.ob_id + ",") == -1) {
          let payload = {
            item_id: this.item.item_id,
            item_parent_object: (this.item.item_parent_object + "," + e.ob_id).replace(/^[,]+|[,]+$/g, ""),
          }
          this.updateItemByField(payload)
        }
      }
    },
    updateVisible(param) {
      let payload = {
        item_id: this.item.item_id,
        item_visible_range: param
      }
      this.updateItemByField(payload);
    },
    saveItemName(ev) {
      let payload = {
        item_id: this.item.item_id,
        item_name: this.nameEdit
      }
      this.updateItemByField(payload);
      this.isNameEdit = false;
    },
    dateFormatter(date) {
      return date.getFullYear() + '/' + (date.getMonth() < 9 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + '/' + date.getDate()
    },
    datePicker(e) {
      let start = e.startDate;
      let end = e.endDate;
      start = new Date(start);
      end = new Date(end);
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      // this.item.item_start_date = start;
      // this.item.item_end_date = end;
      this.updateItemByField({
        item_id: this.item.item_id,
        item_start_date: start,
        item_end_date: end
      })
    },
    stopEvent(ev) {
      ev.stopPropagation();
    },
    cancelDateEdit() {
      this.popupShow = false;
    },
    saveDateInfo() {
      this.updateItemByField({
        item_id: this.item.item_id,
        item_start_date: this.item.item_start_date,
        item_end_date: this.item.item_end_date
      })
      this.popupShow = false;
    },
    saveItemDesc() {
      let payload = {
        item_id: this.item.item_id,
        item_description: this.item.item_description
      }
      this.updateItemByField(payload);
    },
    showObj1() {
      this.isShowObj1 = !this.isShowObj1;
    },
    showObj2() {
      this.isShowObj2 = !this.isShowObj2;
    },
    selectParentObj(obj) {
      let payload = {
        item_id: this.item.item_id,
        item_parent_object: obj.ob_id
      }
      this.updateItemByField(payload);
      this.targetParentMenu = false;
    }
  }
}
</script>

<style scoped>
.remove-parent-object:hover i {
  color: #f32;
}

.basic-information {
  font-size: 14px;
}

.left {
  min-width: 100px;
}

.right {
  width: 90%;
}

.name-edit {
  padding: 5px 0px;
}

.name-edit:hover {
  border-bottom: 1px solid #1565c0;
}

.date-picker {
  border-bottom: 1px solid #ddd;
}

.popup {
  background: #fff;
  min-width: 600px;
  min-height: 400px;
  z-index: 1000;
}
</style><style>
.w-100 {
  width: 100%;
}
</style>
